body {
  margin: 0;
  font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Outer Wrapper */

.wrapper {
  max-width: 2000px;
  margin: 0 auto;
  overflow-x: hidden;
}

/*mouse scroll effect */
.container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.field {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 300px;
}

.mouse {
	width: 50px;
	height: 90px;
	border: 3px solid #016DB7;
	border-radius: 60px;
	position: relative;
	&::before {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #016DB7;
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

.scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #016DB7;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #016DB7;
  	border-bottom: 2px solid #016DB7;
		transform: rotate(-45deg);
	}
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

.arrow {
	width: 0;
	height: 40px;
	border: 1px solid #333;
	position: relative;
	animation: scroll 1.5s infinite;
	-webkit-animation: scroll 1.5s infinite;
	&::after {
		content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: -5px;
    width: 1px;
    height: 10px;
		
    border-top: 10px solid #333;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
	}
}

@keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

@-webkit-keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

/* Header Part */

.header {
  height: 150px;
  background-color: #F8F8F8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}

.header img {
  height: 80px;
  width: auto;
}

.header a {
  padding: 20px 50px;
  border-radius: 10px;
  font-size: 21px;
  font-weight: 500;
  background-color: #006DB8;
  color: white;
  text-decoration: none;
}

.header .mobileIcon {
  display: none;
}

/* Main Part */

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 118px;
}

.main h1 {
  margin: 0;
  color: black;
  text-align: center;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  max-width: 600px;
}

.main h1 span {
  color: #016DB7;
}

.main p {
  margin-top: 20px;
  font-size: 20px;
  line-height: 28px;
  max-width: 600px;
  text-align: center;
  color: #646464;
}

.showButton{
  display: none;
}

.showcasewrapper {
  position: relative;
}

.showcasewrapper .dot {
  position: absolute;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.showcasewrapper .dot img {
  width: 40px;
  height: 40px;
}

.showcasewrapper .dot span {
  color: white;
  background-color: #016DB7;
  position: absolute;
  padding: 5px 10px;
  font-size: 15px;
  min-width: 140px;
  border-radius: 5px;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: all .3s;
  z-index: 1;
}

.showcasewrapper a{
  color: white;
  text-decoration: none;
  position: relative;
}

.showcasewrapper .dot:hover span {
  opacity: 1;
  user-select: all;
  pointer-events: all;
  margin-left: -40px;
}

.showcasewrapper .dot:nth-child(3) span {
  left: -120px;
}

.showcasewrapper .dot:nth-child(4) span {
  top: -20px;
}

.showcasewrapper .dot:nth-child(5) span {
  top: -50px;
}

.showcasewrapper .dot:nth-child(6) span {
  bottom: -20px;
}

.showcasewrapper .dot:nth-child(8) span {
  top: -50px;
}

.showcasewrapper .dot:nth-child(9) span {
  bottom: -80px;
}

.showcasewrapper .dot:nth-child(10) span {
  bottom: -50px;
}

.showcasewrapper .dot:nth-child(12) span {
  bottom: -50px;
  left: 60px;
}

.showcasewrapper .dot:nth-child(13) span {
  bottom: -30px;
}

.showcasewrapper .dot:nth-child(14) span {
  bottom: -30px;
}

.showcasewrapper .dot:nth-child(15) span {
  right: 20px;
}

.showcasewrapper .dot:nth-child(16) span {
  bottom: -40px;
}

.showcasewrapper .dot .highlight {
  width: 30px;
  height: auto;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  transition: .5s all;
  filter: sepia(100%) hue-rotate(175deg) saturate(1000%) brightness(1);
}

.showcasewrapper .dot:hover .highlight {
  opacity: .5;
}

.showcasewrapper .dot:nth-child(1) {
  top: 40px;
  right: 310px;
}

/* Highlights */

.showcasewrapper .dot:nth-child(1) .highlight {
  transform: rotate(115deg);
  margin-top: -15px;
}

.showcasewrapper .dot:nth-child(2) .highlight {
  width: 75px;
  margin-top: -20px;
  margin-left: -5px;
}

.showcasewrapper .dot:nth-child(3) .highlight {
  width: 60px;
  margin-top: -55px;
  margin-left: 33px;
}

.showcasewrapper .dot:nth-child(4) .highlight {
  width: 100px;
  margin-top: -20px;
  margin-left: -25px;
}

.showcasewrapper .dot:nth-child(5) .highlight {
  width: 120px;
  margin-top: -40px;
  margin-left: -35px;
}

.showcasewrapper .dot:nth-child(6) .highlight {
  width: 40px;
  margin-top: -100px;
  margin-bottom: 25px;
  margin-left: -23px;
}

.showcasewrapper .dot:nth-child(7) .highlight {
  width: 28px;
  margin-top: -50px;
  margin-bottom: 20px;
  margin-left: -17px;
}

.showcasewrapper .dot:nth-child(8) .highlight {
  width: 28px;
  margin-top: -50px;
  margin-bottom: 20px;
  margin-left: 35px;
  transform: rotate(-3deg);
}

.showcasewrapper .dot:nth-child(9) .highlight {
  width: 110px;
  margin-top: -60px;
  margin-bottom: 20px;
  margin-left: 5px;
  transform: rotate(-3deg);
}

.showcasewrapper .dot:nth-child(10) .highlight {
  width: 55px;
  margin-top: -80px;
  margin-bottom: 34px;
  margin-left: 15px;
  transform: rotate(-3deg);
}

.showcasewrapper .dot:nth-child(11) .highlight {
  width: 30px;
  margin-top: -110px;
  margin-left: -5px;
}

.showcasewrapper .dot:nth-child(12) .highlight {
  width: 120px;
  margin-top: -55px;
  margin-left: -15px;
}

.showcasewrapper .dot:nth-child(13) .highlight {
  width: 90px;
  margin-top: -100px;
  margin-bottom: 25px;
}

.showcasewrapper .dot:nth-child(14) .highlight {
  width: 45px;
  margin-top: -75px;
  margin-bottom: 30px;
  margin-left: -5px;
}

.showcasewrapper .dot:nth-child(15) .highlight {
  width: 30px;
  margin-top: -170px;
  margin-bottom: 55px;
  margin-left: 30px;
  transform: rotate(-3deg);
}

.showcasewrapper .dot:nth-child(16) .highlight {
  width: 10px;
  margin-top: -100px;
  margin-bottom: 30px;
  margin-left: 35px;
}

/* Dots */

.showcasewrapper .dot:nth-child(2) {
  top: 60px;
  right: 430px;
}

.showcasewrapper .dot:nth-child(3) {
  top: 130px;
  left: 130px;
}

.showcasewrapper .dot:nth-child(4) {
  top: 150px;
  left: 260px;
}

.showcasewrapper .dot:nth-child(5) {
  top: 170px;
  left: 320px;
}

.showcasewrapper .dot:nth-child(6) {
  top: 200px;
  left: 385px;
}

.showcasewrapper .dot:nth-child(7) {
  top: 130px;
  right: 80px;
}

.showcasewrapper .dot:nth-child(8) {
  top: 240px;
  left: 65px;
}

.showcasewrapper .dot:nth-child(9) {
  top: 260px;
  left: 195px;
}

.showcasewrapper .dot:nth-child(10) {
  top: 370px;
  left: 230px;
}

.showcasewrapper .dot:nth-child(11) {
  top: 415px;
  left: 330px;
}

.showcasewrapper .dot:nth-child(12) {
  top: 310px;
  left: 310px;
}

.showcasewrapper .dot:nth-child(13) {
  top: 290px;
  left: 390px;
}

.showcasewrapper .dot:nth-child(14) {
  top: 250px;
  right: 190px;
}

.showcasewrapper .dot:nth-child(15) {
  top: 360px;
  left: 70px;
}

.showcasewrapper .dot:nth-child(16) {
  bottom: 155px;
  left: 285px;
}

.main .itemwrapper {
  width: 700px;
  height: auto;
}

.partwrapper {
  width: 100%;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
}

.innerparts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 100px;
  margin-top: 100px;
}

.innerparts .singlepart {
  flex: 1 0 21%;
  margin: 35px 20px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: .5s all;
}

.innerparts .singlepart a{
  width: 100%;
    height: 100%;
    object-fit: contain;
}

.innerparts .singlepart img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 20px;
  transition: .5s all;
}

.innerparts .singlepart .description {
  width: 100%;
  padding: 8px 0;
  font-size: 20px;
  font-weight: 300;
  background-color: #016DB7;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: .5s all;
}

.innerparts .singlepart:hover .description {
  opacity: 1;
  user-select: all;
  pointer-events: all;
  transform: scale(.6);
}

.innerparts .singlepart:hover img {
  transform: scale(.6);
}

/* Footer Part */

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #005793;
  padding: 50px;
}

.footerline {
  background-color: #3f80ae;
  width: 100%;
  height: 2px;
}

.footercontent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.footercontent h2 {
  margin: 0;
  margin-top: 20px;
  font-size: 29px;
  color: white;
  font-weight: 600;
}

.footercontent p {
  margin: 0;
  margin-top: 20px;
  color: white;
  font-size: 19px;
  max-width: 500px;
}

@media (max-width: 750px) {

  .showButton{
    display: none;
  }
  .main {
    padding: 0 25px;
    padding-top: 80px;
  }
  .main .itemwrapper {
    width: 500px;
  }
  .partwrapper {
    padding: 70px 25px;
  }
  .header img {
    height: 50px;
  }
  .header a {
    padding: 15px 40px;
  }
  .footer {
    padding: 35px 0;
  }
  .footercontent {
    padding: 0 25px;
    width: calc(100% - 25px);
  }
  .footercontent h2 {
    font-size: 18px;
  }
  .footercontent p {
    font-size: 14px;
  }
  .innerparts .singlepart .description {
    width: auto;
    padding: 8px 10px;
  }

  .showcasewrapper .dot:nth-child(1) {
    top: 30px;
    right: 210px;
  }

  .showcasewrapper .dot:nth-child(2) {
    top: 40px;
    right: 300px;
  }

  .showcasewrapper .dot:nth-child(3) {
    top: 90px;
    left: 90px;
  }

  .showcasewrapper .dot:nth-child(4) {
    top: 105px;
    left: 170px;
  }

  .showcasewrapper .dot:nth-child(5) {
    top: 115px;
    left: 220px;
  }

  .showcasewrapper .dot:nth-child(6) {
    top: 140px;
    left: 270px;
  }

  .showcasewrapper .dot:nth-child(7) {
    top: 100px;
    right: 50px;
  }

  .showcasewrapper .dot:nth-child(8) {
    top: 180px;
    left: 45px;
  }

  .showcasewrapper .dot:nth-child(9) {
    top: 180px;
    left: 140px;
  }

  .showcasewrapper .dot:nth-child(10) {
    top: 260px;
    left: 165px;
  }

  .showcasewrapper .dot:nth-child(11) {
    top: 300px;
    left: 230px;
  }

  .showcasewrapper .dot:nth-child(12) {
    top: 215px;
    left: 220px;
  }

  .showcasewrapper .dot:nth-child(13) {
    top: 200px;
    left: 270px;
  }

  .showcasewrapper .dot:nth-child(14) {
    top: 175px;
    right: 130px;
  }

  .showcasewrapper .dot:nth-child(15) {
    top: 260px;
    left: 40px;
  }

  .showcasewrapper .dot:nth-child(16) {
    bottom: 110px;
    left: 195px;
  }

  /* Highlights */

  .showcasewrapper .dot:nth-child(1) .highlight {
    transform: rotate(115deg);
    margin-top: -25px;
    margin-bottom: 10px;
    width: 20px;
  }
  
  .showcasewrapper .dot:nth-child(2) .highlight {
    width: 55px;
    margin-top: -20px;
    margin-bottom: 10px;
    margin-left: -5px;
  }
  
  .showcasewrapper .dot:nth-child(3) .highlight {
    width: 43px;
    margin-top: -55px;
    margin-bottom: 15px;
    margin-left: 28px;
  }
  
  .showcasewrapper .dot:nth-child(4) .highlight {
    width: 65px;
    margin-top: -25px;
    margin-left: 0;
  }
  
  .showcasewrapper .dot:nth-child(5) .highlight {
    width: 90px;
    margin-top: -35px;
    margin-left: -15px;
  }
  
  .showcasewrapper .dot:nth-child(6) .highlight {
    width: 40px;
    margin-top: -100px;
    margin-bottom: 25px;
    margin-left: -23px;
  }
  
  .showcasewrapper .dot:nth-child(7) .highlight {
    width: 20px;
    margin-top: -50px;
    margin-bottom: 37px;
    margin-left: -10px;
  }
  
  .showcasewrapper .dot:nth-child(8) .highlight {
    width: 20px;
    margin-top: -55px;
    margin-bottom: 30px;
    margin-left: 28px;
  }
  
  .showcasewrapper .dot:nth-child(9) .highlight {
    width: 95px;
    margin-top: -60px;
    margin-bottom: 30px;
    margin-left: 0px;
    transform: rotate(-3deg);
  }
  
  .showcasewrapper .dot:nth-child(10) .highlight {
    width: 40px;
    margin-top: -80px;
    margin-bottom: 34px;
    margin-left: 10px;
    transform: rotate(-3deg);
  }
  
  .showcasewrapper .dot:nth-child(11) .highlight {
    width: 21px;
    margin-top: -110px;
    margin-left: 3px;
  }
  
  .showcasewrapper .dot:nth-child(12) .highlight {
    width: 90px;
    margin-top: -45px;
    margin-left: -10px;
  }
  
  .showcasewrapper .dot:nth-child(13) .highlight {
    width: 80px;
    margin-top: -100px;
    margin-bottom: 25px;
  }
  
  .showcasewrapper .dot:nth-child(14) .highlight {
    width: 35px;
    margin-top: -75px;
    margin-bottom: 30px;
    margin-left: -5px;
  }
  
  .showcasewrapper .dot:nth-child(15) .highlight {
    width: 20px;
    margin-top: -140px;
    margin-bottom: 55px;
    margin-left: 33px;
    transform: rotate(-3deg);
  }
  
  .showcasewrapper .dot:nth-child(16) .highlight {
    width: 5px;
    margin-top: -90px;
    margin-bottom: 30px;
    margin-left: 35px;
  }

}

@media (max-width: 550px) {
  .container{
    display: none;
  }

  .header img {
    height: 40px;
  }
  .showButton{
    display: none;
  }

  .header a {
    display: none;
  }
  .header .mobileIcon {
    display: flex;
    background-color: transparent;
    color: black;
    padding: 0;
  }
  .main {
    padding: 0;
    padding-top: 40px;
  }
  .main h1 {
    font-size: 28px;
    line-height: 34px;
    padding: 0 25px;
  }
  .main p {
    font-size: 16px;
    line-height: 26px;
    padding: 0 25px;
  }
  .header {
    padding: 0 25px;
  }
  .innerparts {
    margin: 0;
    margin-top: 30px;
  }
  .innerparts .singlepart {
    width: 50px;
    height: 50px;
  }
  .partwrapper {
    width: auto;
  }
}

@media (max-width: 500px) {
  .main .itemwrapper {
    width: 320px;
  }
  .definition-text{
    display: none;
  }
  .showButton{
    display: block;padding: 20px 50px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 300;
    background-color: #006DB8;
    }

  .showcasewrapper .dot:nth-child(1) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(2) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(3) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(4) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(5) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(6) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(7) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(8) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(9) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(10) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(11) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(12) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(13) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(14) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(15) {
    display: none;
  }

  .showcasewrapper .dot:nth-child(16) {
    display: none;
  }

  /* Highlights */

  .showcasewrapper .dot:nth-child(1) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(2) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(3) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(4) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(5) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(6) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(7) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(8) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(9) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(10) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(11) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(12) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(13) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(14) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(15) .highlight {
    display: none;
  }
  
  .showcasewrapper .dot:nth-child(16) .highlight {
    display: none;
  }
}